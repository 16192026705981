<template>
  <div class="">
    <v-container>
      <v-row align="center" justify="center" class="">
        <v-col cols="12" lg="5">

          <v-card class="rounded mb-5"
                  v-if="isLoading"
                  flat>
            <v-card-text class="pa-6">
              <v-skeleton-loader type="text,text,image,text"></v-skeleton-loader>
            </v-card-text>
          </v-card>

          <v-card class="rounded mb-5"
                  v-else
                  flat>

            <v-card-text v-if="voucher && voucher.service" class="">

              <div class="mb-2 text-center">

                <img alt="" src="@/assets/logo2.png"/>


                <h1 class="my-5 grey--text text--darken-4">E-Ticket</h1>


                <div class="d-flex justify-center mt-5">
                  <qrcode-vue :size="150"
                              :value="voucher.code"
                              background="transparent"
                              foreground="#d60c56"/>
                </div>

                <p class="grey--text text-center text--darken-4 font-weight-bold mt-2">
                  {{ voucher.code }}
                </p>

                <img alt="" width="100%"
                     class="rounded-lg"
                     :src="getImageLink(voucher.service.gallery[0])"/>
              </div>

              <div class="text-center mb-5">

                <h1 class="my-2 grey--text text--darken-4"> {{ voucher.service.name }}</h1>

                <p class="grey--text text--darken-2" v-html="voucher.service.description"></p>


                <a target="_blank"
                   class="text-center mt-5"
                   href="/cgu-eticket"
                   @click.stop
                >
                  Conditions Générales d'Utilisation
                </a>
              </div>


            </v-card-text>

            <v-card-text class="pa-10" v-else>
              <div class="text-center d-flex flex-column align-center">
                <v-icon size="90" class="mb-3" color="error">mdi-alert</v-icon>
                <span>Ce lien est introuvable !</span>
              </div>
            </v-card-text>


          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      isLoading: false,
      currentLink: window.location.href,
      voucher: {}
    };
  },
  methods: {
    check() {
      this.isLoading = true
      axios.get(process.env.VUE_APP_BASE_URL + 'vouchers/check', {
        params: {
          code: window.location.href
        }
      }).then(res => {
        this.voucher = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.check();
  },
};
</script>

<style scoped>
</style>